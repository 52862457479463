var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-card",
          { staticClass: "cardClassDetailInfo", attrs: { noHeader: true } },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "LBLPLANT",
                      value: _vm.equipData.plantName,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "LBL0001720",
                      value: _vm.equipData.equipmentTypeName,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "LBL0001727",
                      value: _vm.equipData.equipmentNo,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "LBL0001725",
                      value: _vm.equipData.equipmentName,
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical",
      },
      [
        _c("c-tab", {
          attrs: {
            type: "vertical",
            tabItems: _vm.tabItems,
            height: _vm.tabheight,
          },
          on: {
            "update:height": function ($event) {
              _vm.tabheight = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (tab) {
                return [
                  _c(tab.component, {
                    tag: "component",
                    attrs: { param: _vm.popupParam },
                    on: { rankChange: _vm.rankChange },
                  }),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }