<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-3">
            <!-- 사업장 -->
            <c-label-text title="LBLPLANT" :value="equipData.plantName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비유형 -->
            <c-label-text title="LBL0001720" :value="equipData.equipmentTypeName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비관리번호 -->
            <c-label-text title="LBL0001727" :value="equipData.equipmentNo"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 설비명 -->
            <c-label-text title="LBL0001725" :value="equipData.equipmentName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
      <c-tab
        type="vertical"
        :tabItems="tabItems"
        :height.sync="tabheight"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :param="popupParam"
            @rankChange="rankChange"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
export default {
  name: 'equipment-etc',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        plantCd: '',
        psmFlag: '',
      }),
    },
    equipData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        equipmentTypeCd: null,  // 설비유형 코드
        equipmentTypeName: '',  // 설비유형명
        equipmentCd: '',  // 설비코드
        equipmentNo: '',  // 설비코드
        equipmentName: '',  // 설비명
        mainProcessCd: '',  // 주공정
        subProcessCd: '',  // 부공정
        managementDepts: '',  // 관리부서
        equipmentLocation: '',  // 설비위치
        installDate: '',  // 설치일자
        installVendor: '',  // 설치업체
        opertationDate: '',  // 가동일자
        checkPassNo: '',  // 검사합격번호
        checkPassDate: '',  // 합격일자
        discardFlag: 'N',
        discardDate: '',  // 폐기일자
        psmFlag: 'N',  // PSM 대상 여부
        psmTypeCd: null,
        amount: '',  // 수량
        lawEquipmentFlag: 'N',  // 법정설비 여부
        inspectionCycleCd: null,  // 법정점검 주기
        selfCycleCd: null,  // 자체점검 주기
        pmCycleCd: null,  // 자체점검 주기
        pmFlag: 'N',  // 예방정비 여부
        cmFlag: 'N',  // 고장정비 여부
        predictiveFlag: 'N',  // 예지정비 여부
        operFlag: 'N', // 운전정비 여부
        relatedLaws: '',  // 관련법규
        recentInspectionDate: '',  // 최근 점검일자
        outsourcingVendor: '',  // 유지보수업체
        hazardousFlag: 'N'
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      tabheight: '',
      tab: 'equipmentGrade',
      tabItems: [
        // 등급 변경
        { name: 'equipmentGrade', icon: 'grade', label: 'LBL0003322', component: () => import(`${'./equipmentGrade.vue'}`) },
        // 자재정보
        { name: 'equipmentBom', icon: 'location_city', label: 'LBL0003323', component: () => import(`${'./equipmentBom.vue'}`) },
        // 관련 MOC
        // { name: 'equipmentMoc', icon: 'published_with_changes', label: 'LBL0003324', component: () => import(`${'./equipmentMoc.vue'}`) },
        // 관련 도면
        { name: 'equipmentDrawing', icon: 'filter', label: 'LBL0003325', component: () => import(`${'./equipmentDrawing.vue'}`) },
        // 관련 인허가
        { name: 'equipmentLicense', icon: 'plagiarism', label: 'LBL0003326', component: () => import(`${'./equipmentLicense.vue'}`) }
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.tabheight = (window.innerHeight - 200) + 'px'
    },
  }
};
</script>